<template>
	<div class="flex flex-col bg-slate-900 flex-grow">
		<div class="flex flex-col flex-grow relative px-6 pt-8 lg:pt-12 mb-12 overflow-hidden">
			<BgLanding
				class="absolute left-0 top-0 w-full h-full blur-2xl z-[0] transform scale-125"
				preserveAspectRatio="none"
			/>
			<div class="absolute left-0 top-0 right-0 bottom-0 z-[0] flex flex-col">
				<BgLandingMask class="w-full h-auto text-slate-900" preserveAspectRatio="none" />
				<div class="bg-slate-900 flex-grow -mt-px"></div>
			</div>
			<div class="w-full max-w-screen-xl self-center z-[1] flex flex-col">
				<div class="flex items-center">
					<NuxtLink :to="$routeUrl.StartUrl()">
						<img
							src="/img/app-logo.png"
							class="h-14 w-14 object-contain bg-white rounded-xl p-2.5 shadow-lg"
							alt=""
						/>
					</NuxtLink>
					<div class="flex-grow" />
					<span
						class="flex items-center justify-center w-8 h-8 text-sm font-black uppercase duration-300 bg-white rounded-full cursor-pointer bg-opacity-10 hover:bg-opacity-25 start-auto text-white me-6 border border-white border-opacity-25"
						@click="state.isLanguageSelectVisible = true"
					>
						{{ app.$i18n.locale.value }}
					</span>
					<NuxtLink
						:to="$routeUrl.StartUrl()"
						class="text-sm font-bold text-white bg-[#FF6801] rounded-xl px-12 py-3 shadow-lg"
					>
						{{ $t("app") }}
					</NuxtLink>
				</div>
				<div class="grid grid-cols-1 lg:grid-cols-12 gap-x-16 gap-y-12 mb-6 mt-12 lg:mt-20">
					<div class="col-span-1 lg:col-span-7 flex flex-col order-2 lg:order-1 py-6">
						<div class="h-2 rounded-full bg-white w-16 mb-6"></div>
						<h1 class="font-bold text-white text-3xl lg:text-5xl max-w-lg" :style="{ lineHeight: 1.5 }">
							{{ $t("app_name") + ": " + $t("app_motto") }}
						</h1>
						<p class="text-white text-lg lg:text-xl mt-5" :style="{ lineHeight: 1.75 }">
							{{ $t("app_description") }}
						</p>
						<div class="flex items-start flex-wrap mt-8 -me-4 -mb-4">
							<template v-for="(button, i) in installButtons" :key="i">
								<NuxtLink
									v-if="button.link"
									:to="button.link"
									external
									:class="[
										'text-white rounded-xl ps-5 pe-6 py-3 me-4 mb-4 cursor-pointer',
										button.bgClass
									]"
								>
									<Icon :name="button.icon" class="w-8 h-8 me-4" />
									<span class="text-sm font-bold">
										{{ button.title }}
									</span>
								</NuxtLink>
								<span
									v-else
									:class="[
										'text-white rounded-xl ps-5 pe-6 py-3 me-4 mb-4 cursor-pointer',
										button.bgClass
									]"
									@click="() => button.onClick?.()"
								>
									<Icon :name="button.icon" class="w-8 h-8 me-4" />
									<span class="text-sm font-bold">
										{{ button.title }}
									</span>
								</span>
							</template>
						</div>
					</div>
					<div class="col-span-1 lg:col-span-5 order-1 lg:order-2 flex flex-col">
						<div class="relative w-full self-center max-w-lg">
							<div class="pt-[150%]"></div>
							<div class="absolute left-0 top-0 right-0 bottom-0">
								<Swiper
									@init="(s) => (swiper = s)"
									@slide-change="onSlideChange"
									:space-between="24"
									class="w-full h-full z-[1]"
									:slides-per-view="1"
									:autoplay="{
										delay: 3000
									}"
									loop
									:modules="[Autoplay]"
								>
									<SwiperSlide v-for="(item, i) in introItems" :key="i" class="relative">
										<div
											class="flex flex-col absolute left-0 top-0 right-0 bottom-0 overflow-hidden"
										>
											<div class="mx-8 my-8 flex items-center relative flex-grow shadow-inner">
												<img
													:src="item.image"
													alt=""
													class="absolute w-full h-full object-contain"
												/>
											</div>
											<span
												class="text-center font-bold text-lg text-white border rounded-lg border-slate-500 p-4 bg-slate-500 bg-opacity-10"
											>
												{{ item.title }}
											</span>
										</div>
									</SwiperSlide>
								</Swiper>
							</div>
						</div>
						<div class="flex items-center self-center mt-6">
							<NuxtLink
								class="text-sm bg-gradient-to-tr from-[#ffa726] to-[#ffcc80] px-4 py-2.5 rounded-full text-white self-center font-bold shadow-lg cursor-pointer me-6"
								@click="() => swiper?.slidePrev()"
							>
								{{ $t("prev") }}
							</NuxtLink>
							<NuxtLink
								class="text-sm bg-gradient-to-tr from-[#ffa726] to-[#ffcc80] px-4 py-3 rounded-full text-white self-center font-bold shadow-lg cursor-pointer"
								@click="() => swiper?.slideNext()"
							>
								{{ $t("next") }}
							</NuxtLink>
						</div>
					</div>
				</div>
			</div>
		</div>
		<AuthStartFooter />
		<MainLanguageSelectDialog
			:visible="state.isLanguageSelectVisible"
			@close="state.isLanguageSelectVisible = false"
		/>
	</div>
</template>

<script setup lang="ts">
import BgLanding from "~/assets/svg/bg-landing.svg"
import BgLandingMask from "~/assets/svg/bg-landing-mask.svg"
import type Swiper from "swiper"
import { Autoplay } from "swiper/modules"
import { Capacitor } from "@capacitor/core"

const runtimeConfig = useRuntimeConfig()
const app = useNuxtApp()
const user = useSupabaseUser()

definePageMeta({
	middleware: ["noauth"]
})

const state = reactive({
	deferredPrompt: null as any,
	isLanguageSelectVisible: false,
	activeIndex: 0
})

const swiper = ref<Swiper>()

const introItems = computed(() => [
	{
		name: app.$i18n.t("intro_name_1"),
		title: app.$i18n.t("intro_title_1"),
		image: "/img/intro/intro-1.png",
		buttonTitle: ""
	},
	{
		name: app.$i18n.t("intro_name_2"),
		title: app.$i18n.t("intro_title_2"),
		image: "/img/intro/intro-2.png"
	},
	{
		name: app.$i18n.t("intro_name_3"),
		title: app.$i18n.t("intro_title_3"),
		image: "/img/intro/intro-3.png"
	},
	{
		name: app.$i18n.t("intro_name_4"),
		title: app.$i18n.t("intro_title_4"),
		image: "/img/intro/intro-4.png"
	},
	{
		name: app.$i18n.t("intro_name_5"),
		title: app.$i18n.t("intro_title_5"),
		image: "/img/intro/intro-5.png"
	},
	{
		name: app.$i18n.t("intro_name_6"),
		title: app.$i18n.t("intro_title_6"),
		image: "/img/intro/intro-6.png"
	}
])

const installButtons = [
	{
		title: app.$i18n.t("direct_download"),
		icon: "mdi:android",
		bgClass: "bg-[#A4C639]",
		link: runtimeConfig.public.siteUrl + "/vivai-1.0.2.apk",
		onClick: null
	},
	{
		title: app.$i18n.t("install"),
		icon: "simple-icons:pwa",
		bgClass: "bg-[#607d8b]",
		link: null,
		onClick: async () => {
			if (app.$pwa?.isPWAInstalled) {
				app.$toast.info({ message: app.$i18n.t("app_already_installed") })
			} else {
				window.beforeInstallPrompt?.prompt()
			}
		}
	}
]

const onSlideChange = (swiper: Swiper) => {
	state.activeIndex = swiper.activeIndex
}

const isInStandaloneMode = () =>
	window.matchMedia("(display-mode: standalone)").matches ||
	// @ts-ignore
	window.navigator.standalone ||
	document.referrer.includes("android-app://")

onMounted(() => {
	if (Capacitor.isNativePlatform() || isInStandaloneMode()) {
		if (!user.value) {
			app.$router.replace(app.$routeUrl.StartUrl())
		}
	}
})
</script>
